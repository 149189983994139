
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import Navbar from './components/navbar/Navbar';
import SideBar from './components/sidebar/SideBar';

import Signup from './components/signup/Signup';
import Login from './components/login/Login';
import Homepage from './components/homepage/Hompage';
import Golive from './components/golive/Golive';
import Messages from './components/messages/Messages';
import Protected from './components/Protected';
import Chats from './components/chats/Chats'
import Billing from './components/billing/Billing';
import WebHooks from './components/webhooks/WebHooks';
import Topup from './components/topup/Topup';
import Settings from './components/settings/Settings';
import Profile from './components/profile/Profile';
import ConfirmEmail from './components/confirmationPage/ConfirmEmail';
import PageNotFound from './components/pageNotFound/PageNotFound';
import OrderStatusPage from './components/orderStatusPage/OrderStatusPage';
import ForgotPassword from './components/forgotpassword/ForgotPassword'
import ResetPassword from './components/resetpassword/ResetPassword';
import SupportWidget from './components/supportWidget/SupportWidget';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo, setUserToken, setLoginState, setSandBoxNumber, logoutUser, setApiKey, setCallbackUrl, setStatusCallbackUrl } from './redux-store/AuthSlice';
import jwt_decode from "jwt-decode";

import './App.css'


function App() {
  const dispatch = useDispatch()
  const selector = useSelector((state) => state.auth)

  let navigate = useNavigate()

  const useBeforeRender = (callback, deps) => {
    const [isRun, setIsRun] = useState(false);
    if (!isRun) {
      callback();
      setIsRun(true);
    }
    useEffect(() => () => setIsRun(false), [])
  };

  // localStorage.clear()

  useBeforeRender(() => {
    dispatch(setUserToken(localStorage.getItem("token")))
    dispatch(setUserInfo(localStorage.getItem("userinfo")))
    dispatch(setLoginState(localStorage.getItem("loginState")))
    dispatch(setSandBoxNumber(localStorage.getItem("sandBoxNumber")))
    dispatch(setApiKey(localStorage.getItem("apikey")))
    dispatch(setCallbackUrl(localStorage.getItem("callbackUrl")))
    dispatch(setStatusCallbackUrl(localStorage.getItem("statusCallbackUrl")))
  }, [dispatch]);


  if (selector.loggedIn) {
    const decodedToken = jwt_decode(localStorage.getItem("token"))
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      return <div className='session-modal-active'>
        <div className="session-modal" >
          {/* <img id="session-img" src="https://100dayscss.com/codepen/alert.png" width="44" height="38" alt='warning' /> */}
          <span className="session-title">Your session expired</span>
          <p className='session-message'>Please log in to continue using the application.</p>
          <div className='session-buttons'>
            <button type='button' className="session-dismiss-button" onClick={() => { localStorage.clear(); dispatch(logoutUser()); navigate('/auth/login') }}>Log in </button>
          </div>
        </div>
      </div>
    }
  }


  const handleToggleSideBar = () => {
    var y = document.getElementById("toggle-bar");
    if (y.style.display === "block") {
      y.style.display = "none";

    } else {
      y.style.display = "block";
      y.style.position = "absolute"
    }
  }
  

  const DefaultContainer = () => (

    <div className='main-page'>

      <SideBar idName="toggle-bar" />

      <div id='right-content' className='right'>
        <Navbar toggleSideBar={handleToggleSideBar}/>
        <div className='content-div'>

          <Routes>
            <Route exact path='/home' element={<Protected isLoggedIn={selector.loggedIn}><Homepage /></Protected>} />
            <Route exact path='/golive' element={<Protected isLoggedIn={selector.loggedIn}><Golive /></Protected>} />
            <Route exact path='/messages' element={<Protected isLoggedIn={selector.loggedIn}><Messages /></Protected>} />
            <Route exact path='/chats' element={<Protected isLoggedIn={selector.loggedIn}><Chats /></Protected>} />
            <Route exact path='/billing' element={<Protected isLoggedIn={selector.loggedIn}><Billing /></Protected>} />
            <Route exact path='/web-hooks' element={<Protected isLoggedIn={selector.loggedIn}><WebHooks /></Protected>} />
            <Route exact path='/settings' element={<Protected isLoggedIn={selector.loggedIn}><Settings /></Protected>} />
            <Route exact path='/profile' element={<Protected isLoggedIn={selector.loggedIn}><Profile /></Protected>} />
            <Route exact path='/topup' element={<Protected isLoggedIn={selector.loggedIn}><Topup /></Protected>} />
            <Route exact path='/order_status' element={<Protected isLoggedIn={selector.loggedIn}><OrderStatusPage /></Protected>} />
            <Route exact path='/support' element={<Protected isLoggedIn={selector.loggedIn}><SupportWidget /></Protected>} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </div>

      </div>
    </div>

  );

  const AuthContainer = () => (

    <Routes>
      <Route exact path='/signup' element={<Signup />} />
      <Route exact path='/login' element={<Login />} />
      <Route exact path='/forgot_pass' element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>

  );

  const NotFoundContainer = () => (

    <Routes>
      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>

  );

 

  return (
   <>
    <Routes>
      <Route exact path='auth/*' element={<AuthContainer />} />
      <Route exact path='app/*' element={<DefaultContainer />} />
      <Route exact path='/confirm/email/:token' element={<ConfirmEmail />} />
      <Route exact path='/reset_password/:token' element={<ResetPassword />} />
      <Route exact path='*' element={<NotFoundContainer />} />
      <Route exact path="/" element={<Navigate to="/app/home" />} />
    </Routes>
    <SupportWidget />
    </>

  );
}

export default App;
