import React, { useState, useEffect } from 'react'
import "./billing.css"
import { DataGrid} from '@mui/x-data-grid';
import { getAllBilling, getStatements } from '../../apis/Billing';
import {getRegisteredNumbers} from '../../apis/RegisterdNumbers'

import { useDispatch, useSelector } from 'react-redux';
import { Toaster, toast } from 'sonner'
import dateFormat from 'dateformat';
import Loading from '../ReUsedComponents/loadingScreen/Loading';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

import { Table, Pagination, Spin }from "antd";

const Billing = () => {

    const dispatch = useDispatch()
    const action = useSelector((state) => state.action)

    const [allBillings, setAllBillings] = useState([])
    const [allStatements, setAllStatements] = useState([])

    const [registeredWabaNumbers, setRegisteredWabaNumbers] = useState([])
    const [wabaPhoneNumber, setWabaPhoneNumber] = useState('');
    const [totalCoversionCost, setTotalCoversionCost] = useState(0);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState();
  
    const [pageSize, setPageSize] = useState(25);
    const [pageNo, setPageNo] = useState(1);

    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)
  
    const handleChange = ([newStartDate, newEndDate]) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    useEffect(() =>{ 
  
        getAllBilling(dispatch, pageSize, pageNo, startDate? format(startDate, 'dd-MM-yyyy'):'', endDate? format(endDate, 'dd-MM-yyyy'):'', wabaPhoneNumber).then(resp => {
            if(resp.status === 200){
                setAllBillings(resp.data?.data)
                var total = 0;
                resp.data?.data?.data.map((item) => total = total + item.amount)
                setTotalCoversionCost(total)
                
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

           getRegisteredNumbers(dispatch).then(resp => {
            if(resp.status === 200){
              setRegisteredWabaNumbers(resp.data?.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

           getStatements(dispatch, pageSize, pageNo).then(resp => {
            console.log(resp)
            if(resp.status === 200){
              setAllStatements(resp.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

    }, [dispatch, pageNo, pageSize, startDate, endDate, wabaPhoneNumber])


    const conversationColumns = [
        {field: 'wabaPhoneNumber', headerName: 'WABA Number',flex:1, minWidth: 150, headerClassName: 'custom-table-header first-header',sortable: false,
        colSpan: ({ row }) => {
            if (row.id === 'TOTAL') {
              return 4;
            }
          },
          valueGetter: ({ value, row }) => {
            if (row.id === 'TOTAL') {
              return row.label;
            }
            return row.wabaPhoneNumber;
          },
    },

        {field: 'whatsappConversationId', headerName: 'Conversation ID',flex:1, minWidth: 300,headerClassName: 'custom-table-header',sortable: false,},
        {field: 'userPhoneNumber', headerName: 'User Phone Number',flex:1, width: 140, headerClassName: 'custom-table-header first-header',sortable: false,},
        {field: 'type', headerName: 'Conversation Type',flex:1, width: 120, headerClassName: 'custom-table-header first-header',sortable: false,},
        {field: 'amount', headerName: 'Conversation Cost',flex: 1, width: 120, headerClassName: 'custom-table-header first-header',sortable: false,
        valueGetter: ({ row }) => {
            if (row.id === 'TOTAL') {
              return row.total;
            }
            return row.amount;
          },},
        { field: 'createdAt', headerName: 'Created At',width: 150, headerClassName: 'custom-table-header',sortable: false,
        renderCell: (props)=> <span>{dateFormat(props.row?.createdAt, "dd/mm/yyyy HH:MM")}</span>},
    ];

    const statementColumns = [
      {
          title: 'Transaction',
          dataIndex: 'transactionType',
          key: 'transactionType',
          width: 150,
          sorter: (a, b) => a.transactionType.length - b.transactionType.length,
      },
      {
          title: 'Transaction Type',
          dataIndex: 'drCrFlag',
          key: 'drCrFlag',
          width: 150,
          sorter: (a, b) => a.drCrFlag.length - b.drCrFlag.length,
      },
      {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
          width: 150,
          sorter: (a, b) => a.amount - b.amount,
      },
      {
          title: 'Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          width: 150,
          render: (_, record) => <span>{dateFormat(record.createdAt, "dd/mm/yyyy HH:MM")}</span>,
          sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      },
  ];
  
  const conversationRows = [
        ...allBillings.length === 0? [] : allBillings?.data,
        { id: 'TOTAL', label: 'Total', total: totalCoversionCost},
      ];

const handlePaginationChange = (page, size) => {
  setPageNo(page);
  setPageSize(size);
};

console.log(allStatements)


    return (
        <div class='table-page-api'>    
           <div>

            <div style={{marginLeft: '10px', display:"flex"}}>
                <p className={`step step1-${step1}`} onClick={() => {setStep1(true); setStep2(false)}}>Conversations</p>
                <p className={`step step1-${step2}`} onClick={() => {setStep2(true); setStep1(false)}}>Statements</p>    
            </div>
      
            {/* <p className='table-page-title-api'>{step1? "Conversations" : "Statements"}</p> */}
              
          
            <div className='table-toolbar-api'>
                    <div className='filter-messages-section-api'>
                        <label className='filter-label-api'>Filter by business number: </label>
                        <select type='text' className='filter-select-field-api' value={wabaPhoneNumber} onChange={(e) => { setWabaPhoneNumber(e.target.value) }}>
                        <option id='option' value="" selected>All</option>
                        <option id='option' value="254110090747" selected>Sandbox Number</option>
                        {registeredWabaNumbers.map((item, id) =>
                            <option id='option' key={id} style={{ fontSize: '14px' }} value={item.number}>{item.number}</option>
                        )}
                        </select>
                    </div>

                <div className='date-range-container'>
                <div className='date-range-section'>
                    <p id='date-range-header'>Date Range</p>
                    <div className="featured-custom-datepicker">
                        <DatePicker selected={''} onChange={handleChange}
                            selectsRange startDate={startDate} endDate={endDate} dateFormat="dd/MM/yyyy" />
                    </div>
                </div>
              </div>

             </div>

             {step1?
            <div>
           {allBillings.length === 0? <Loading /> : 
            <DataGrid rowHeight={40} rows={conversationRows} columns={conversationColumns} disableColumnMenu
                    className="datagrid-root"  getRowClassName="dashboard-transaction-rows"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) =>setPageSize(newPageSize)}
                    onPageChange={(newPage) => setPageNo(newPage)}
                    rowsPerPageOptions={[25, 50, 100]}
                    rowCount={allBillings?.totalElements}
                    paginationMode="server"
                    pagination
                    componentsProps={{panel: { className: "custom-colum-panel",}, }}
                    sx={{border: 'none',}} 
                    autoHeight
                    
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder

                    />}
            </div>
            :

          <div className='data-table-section' style={{ height: '56vh' }}> 
          <Spin spinning={action.pending}>
              <Table dataSource={allStatements?.data} columns={statementColumns}
                pagination={false}
                scroll={{ y: 'calc(76vh - 200px)',}}
                sticky
                className="custom-table"
              /> 
              <div style={{ padding: '10px 5px', textAlign: 'center' }}>
              <Pagination
                className="custom-pagination"
                align='end'
                total={allStatements?.totalElements}
                showTotal={(_, range) => `${range[0]}-${range[1]} of ${allStatements?.totalElements} items`}
                current={pageNo}
                pageSize={pageSize}
                onChange={handlePaginationChange}
                onShowSizeChange={(current, size) => {
                  setPageSize(size);
                  setPageNo(1);
                }}
                showSizeChanger
              />
              </div>
            </Spin>   
          </div> 

            }
          
            </div>

            <Toaster position="top-right" expand={false} richColors/>

        </div>
    )
}

export default Billing