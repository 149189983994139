import React, { useState } from "react";
import axios from "axios";

const SupportWidget = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async () => {
        if (!title || !feedback) {
            alert("Please fill in all fields.");
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post(
                "https://api.clickup.com/api/v2/list/901207416609/task",
                {
                    name: title,
                    description: feedback,
                },
                {
                    headers: {
                        Authorization: process.env.REACT_APP_CLICK_UP_API_KEY,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200 || response.status === 201) {
                setSuccess(true);
                setFeedback("");
                setTitle("");
            } else {
                alert("Failed to submit feedback. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting feedback:", error);
            alert("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: "9999" }}>
            {isOpen ? (
                <div
                    style={{
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        background: "#fff",
                        width: "350px",
                    }}
                >
                    {success ? (
                        <div>
                            <p>Thank you for your feedback!</p>
                            <button
                                onClick={() => {
                                    setSuccess(false);
                                    setIsOpen(false);
                                }}
                                style={{
                                    backgroundColor: "#007FFF",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "5px 25px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                }}
                            >
                                Close
                            </button>
                        </div>
                    ) : (
                        <>
                            <h4>Send Feedback</h4>
                            <input
                                type="text"
                                placeholder="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                style={{
                                    width: "100%",
                                    padding: "6px 8px",
                                    marginBottom: "10px",
                                    borderRadius: "6px",
                                    border: "1px solid rgb(206, 206, 206)",
                                    outline: "none",
                                }}
                            />
                            <textarea
                                placeholder="Your feedback"
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                style={{
                                    width: "100%",
                                    padding: "8px",
                                    height: "150px",
                                    marginBottom: "10px",
                                    borderRadius: "6px",
                                    border: "1px solid rgb(206, 206, 206)",
                                    outline: "none",
                                }}
                            />
                            <button
                                onClick={handleSubmit}
                                disabled={loading}
                                style={{
                                    backgroundColor: "#007FFF",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "8px 15px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                }}
                            >
                                {loading ? "Submitting..." : "Submit"}
                            </button>
                            <button
                                onClick={() => setIsOpen(false)}
                                style={{
                                    backgroundColor: "#dc3545",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "8px 15px",
                                    cursor: "pointer",
                                }}
                            >
                                Close
                            </button>
                        </>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => setIsOpen(true)}
                >
                    {/* Text and Icon */}
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px", // Spacing between text and icon
                            fontSize: "14px",
                            color: "#555",
                            fontWeight: "bold",
                            marginBottom: "5px",
                        }}
                    >
                        <span>Support & Feedback</span>
                        <span className="material-symbols-outlined" style={{ fontSize: "18px" }}>
                            chat
                        </span>
                    </div>
                    {/* Circular Button */}
                    <button
                        style={{
                            backgroundColor: "#007bff",
                            color: "#fff",
                            border: "none",
                            width: "60px",
                            height: "60px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            fontSize: "24px",
                        }}
                    >
                        <span className="material-symbols-outlined" style={{ fontSize: "30px" }}>
                            chat
                        </span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default SupportWidget;
