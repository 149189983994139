import React, {useEffect, useState} from 'react';
import './golive.css'
import { registeredNumber} from '../../apis/RegisterdNumbers'
import { useDispatch, useSelector } from 'react-redux';
import { Toaster, toast } from 'sonner'

const Golive = () => {

  const dispatch = useDispatch()    
  const action = useSelector((state) => state.action)

  const [phoneNumber, setPhoneNumber] = useState()
  const [phoneNumberModal, setPhoneNumberModal] = useState(false)

  const [formErrors, setFormErrors] = useState()

  useEffect(() => {
  
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : '564683789153111',
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v17.0'
      });
    };

    (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  }, [dispatch])
 

  const validate = () => {
    let errors = {}
    if (!phoneNumber) {
      errors['phoneNumber'] = 'Phone number is required!'
    }
    setFormErrors(errors)
    return errors
  };

 // Facebook Login with JavaScript SDK
  const launchWhatsAppSignup = () => {
    if (Object.keys(validate()).length === 0) {

    window.FB.login((response) => {
      if (response.authResponse) {
        const accessToken = response.authResponse.accessToken
        console.log(accessToken)
        // Use this token to call the debug_token API and get the shared WABA's ID
        // You might want to call your API here
      } else {
        console.log('User cancelled login or did not fully authorize.')
      }
    }, {
      scope: 'whatsapp_business_management',
      extras: {
        feature: 'whatsapp_embedded_signup',
        setup: {}
      }
    })
   }

  }


  const handleRegisterNumber = () => {
    if (Object.keys(validate()).length === 0) {
      registeredNumber(dispatch, {phoneNumber: phoneNumber}).then(resp => {
              console.log(resp)
              if (resp?.status === 200) {
                if (resp?.data.error === true) {
                  toast.error(resp?.data.message, { theme: "colored" })
                } else {
                  handleCloseModal(dispatch)
                  launchWhatsAppSignup()
                }
              }
              else { toast.error("Error occurred!", { theme: "colored" }) }
            })
        }
  }

const handleCloseModal = (dispatch) => {
  setPhoneNumber('')
  setPhoneNumberModal(false)
  document.getElementById("form-modal").reset();
}

const loading = () =>{
  return <div class="lds-dual-ring"></div>
}

  return (
    <div>
    <div className='whatsapp-manager'>

      <div className='setup-section'>
      <p className='setup-title'>Go Live: Register your number on WhatsApp for Business API</p>
        {/* <p className='setup-title-p'>Follow the steps below to register your number on WhatsApp for Business API through Lipachat.</p>
           */}
        <ul className='points-to-note'>
          <p>Points to Note: </p>
          <li>If your number is registered on the phone WhatsApp or WhatsApp for business application, de-link it</li>
          <li>In case of any issues during the process, email us on <span id='support-email'>chat@lipachat.com</span></li>
        </ul>

    
        <div className='setup-steps-section'>
          <p id='golive-step'>
            <img src={process.env.PUBLIC_URL + "/images/whatsapp-logo.svg"} alt="hero" />
            <p style={{fontWeight:'bold', fontSize:'14px'}}>WhatsApp</p> 
             <p>Authorize Lipachat to manage your WhatsApp Business Account.</p> 
             
             <button type="button" onClick={() => setPhoneNumberModal(true)} className='facebook-login-btn'>
              Connect<span class="material-symbols-outlined" style={{fontSize:'18px', marginLeft:'10px'}}>arrow_forward</span></button>
          </p>

          {/* <p id='golive-step'>
             <img src={process.env.PUBLIC_URL + "/images/authorization.png"} alt="hero" />
            Allow access to the necessary permissions for Lipachat to function properly.</p>

          <p id='golive-step'>
          <img src={process.env.PUBLIC_URL + "/images/broken-link.png"} alt="hero" />
            Link your WhatsApp Business Account as instructed by the prompt.</p>

          <p id='golive-step'>
          <img src={process.env.PUBLIC_URL + "/images/integration.png"} alt="hero" />
            Upon successful linking, Lipachat will be able to provide WhatsApp integration services for your account.</p> */}

        </div>
      </div>

      
      {/* <div className='facebook-login-btn-section'>
       <button type="button" onClick={() => setPhoneNumberModal(true)} className='facebook-login-btn'>Connect Your Whatsapp Number</button>
       </div> */}
    </div>


      {/* /////////////////////enter password modal  */}
      <div className={phoneNumberModal ? 'create-modal-active' : 'create-modal-inactive'} >
        <form id="form-modal" className='view-modal-content'>
          <p className='modal-title'> Enter Phone Number
          <span id='close-button' class="material-symbols-outlined" onClick={() => handleCloseModal(dispatch)}>close</span>
            </p>
            {/* <p style={{fontSize: '13px', color: '#494F55'}}>Generating a new API Key will result to old key not working</p> */}
          <div className="dialog-inputs">
          <div className="password-form-group">
                <input type='number' name='phoneNumber' className="password-form-control" onChange={(e)=> setPhoneNumber(e.target.value)} 
                placeholder='include country code'/>
                <span id="password-error">{formErrors?.phoneNumber}</span>
            </div>
          </div>
          <div className='dialog-footer'>
            
            <div>
            {!action.pending?
                <button type='button' className="submit-btn" onClick={handleRegisterNumber}>Connect</button>:
                <button className="submit-btn-disabled" disabled={true}>{loading()}</button>
            }
            </div>

          </div>
        </form>
      </div>


          <Toaster position="top-right" expand={false} richColors/>

    </div>
  );


}

export default Golive