import React, { useState, useEffect } from 'react'
import "../ReUsedComponents/table.css"

import { getAllMessages } from '../../apis/Messages';
import {getRegisteredNumbers} from '../../apis/RegisterdNumbers'

import { useDispatch, useSelector } from 'react-redux';
import { Toaster, toast } from 'sonner'
import dateFormat from 'dateformat';

import { Table, Pagination, Spin }from "antd";


const Messages = () => {

    const dispatch = useDispatch()
    const action = useSelector((state) => state.action)

    const [incomingMessages, setIncomingMessages] = useState([])
    const [outgoingMessages, setOutgoingMessages] = useState([])
  
    const [pageSize, setPageSize] = useState(25);
    const [pageNo, setPageNo] = useState(1);

    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)

    const [whatsappNumber, setWhatsappNumber] = useState('')
    const [inboundUserNumber, setInboundUserNumber] = useState('')
    const [outboundUserNumber, setOutboundUserNumber] = useState('')
    const [registeredWabaNumbers, setRegisteredWabaNumbers] = useState([])
    const [sortedInfo, setSortedInfo] = useState({});

        
    useEffect(() =>{ 
        getAllMessages(dispatch, pageSize, pageNo, whatsappNumber ,"INBOUND", inboundUserNumber).then(resp => {
            if(resp.status === 200){
                setIncomingMessages(resp.data?.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

           getAllMessages(dispatch, pageSize, pageNo, whatsappNumber, "OUTBOUND", outboundUserNumber).then(resp => {
            if(resp.status === 200){
                setOutgoingMessages(resp.data?.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

           getRegisteredNumbers(dispatch).then(resp => {
            if(resp.status === 200){
              setRegisteredWabaNumbers(resp.data?.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

    }, [dispatch,pageNo, pageSize, whatsappNumber, inboundUserNumber, outboundUserNumber, step1, step2 ])

    // const getMessagesByContact = (contactId) => {
    //     dispatch(setContact(contactId))
    //     navigate('/app/chats')
    // }

    const columns = [
        {
            title: 'WABA Number',
            dataIndex: 'whatsappNumber',
            key: 'whatsappNumber',
            width: 150,
            sorter: (a, b) => a.whatsappNumber.length - b.whatsappNumber.length,
            sortOrder: sortedInfo.columnKey === 'whatsappNumber' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type.length - b.type.length,
            sortOrder: sortedInfo.columnKey === 'type' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: 'Message',
            dataIndex: 'text',
            key: 'text',
            width: 120,
        },
        {
            title: 'Media Url',
            key: 'mediaUrl',
            render: (_, record) =>
                record.mediaUrl ? (
                    <a href={record.mediaUrl} target='_blank' rel="noreferrer">Media Url</a>
                ) : '_ _',
            sorter: (a, b) => a.mediaUrl?.length - b.mediaUrl?.length,
        },
        {
            title: 'Contact',
            key: 'contact',
            render: (_, record) => record.contact?.name,
            sorter: (a, b) => a.contact?.name?.length - b.contact?.name?.length,
            sortOrder: sortedInfo.columnKey === 'contact' ? sortedInfo.order : null,
        },
        {
            title: 'Phone Number',
            key: 'phoneNumber',
            render: (_, record) => record.contact?.phoneNumber,
            sorter: (a, b) => a.contact?.phoneNumber?.length - b.contact?.phoneNumber?.length,
            sortOrder: sortedInfo.columnKey === 'phoneNumber' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: 'Status',
            key: 'status',
            render: (_, record) => (
                <span id={`status-${record.status}`}>{record.status}</span>
            ),
            sorter: (a, b) => a.status.length - b.status.length,
            sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => <span>{dateFormat(record.createdAt, "dd/mm/yyyy HH:MM")}</span>,
            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
            sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
            ellipsis: true,
        },
    ];
    
      const handlePaginationChange = (page, size) => {
        setPageNo(page);
        setPageSize(size);
      };

      const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setSortedInfo(sorter);
      };
 
  
    return (
        <div className='table-page-api'>    

            <div style={{marginLeft: '10px', display:"flex"}}>
                <p className={`api-step step1-${step1}`} onClick={() => {setStep1(true); setStep2(false); document.getElementById('myInput').value = ''; setOutboundUserNumber('')}}>Incoming Messages</p>
                <p className={`api-step step1-${step2}`} onClick={() => {setStep2(true); setStep1(false); document.getElementById('myInput').value = ''; setInboundUserNumber('')}}>Outgoing Messages</p>    
            </div>
           
            <div>
    
            <div className='table-toolbar-api' id='messages-table-toolbar'>
                    <div className='filter-messages-section-api'>
                        <label className='filter-label-api'>Filter by business number: </label>
                        <select type='text' className='filter-select-field-api' value={whatsappNumber} onChange={(e) => { setWhatsappNumber(e.target.value) }}>
                        <option id='option' value="" selected>All</option>
                        <option id='option' value={`whatsappNumber=+254110090747`}>Sandbox Number</option>
                        {registeredWabaNumbers.map((item, id) =>
                            <option id='option' key={id} style={{ fontSize: '14px' }} value={`whatsappNumber=${item.number}`}>{item.number}</option>
                        )}
                        </select>
                    </div>

                   {step1?
                     <div className='search-table-input-api'>
                       <label className='filter-label-api'>Filter by user number: </label>
                       <input type='text' className="table-input-api" placeholder='Search...' onChange={(e) => setInboundUserNumber(e.target.value === '' ? '' : `userNumber=${e.target.value}`)} id="myInput"/>
                   </div>
                  :
                  <div className='search-table-input-api'>
                    <label className='filter-label-api'>Filter by user number: </label>
                    <input type='text' className="table-input-api" placeholder='Search...'  onChange={(e) => setOutboundUserNumber(e.target.value === '' ? '' : `userNumber=${e.target.value}`)} id="myInput" />
                  </div>
                    }     
             </div>

            


            {step1?
              <div className='data-table-section' style={{ height: '60vh' }}> 
                <Spin spinning={action.pending}>
                    <Table dataSource={incomingMessages?.data} columns={columns}
                      pagination={false}
                      onChange={handleChange}
                      scroll={{ y: 'calc(78vh - 200px)',}}
                      sticky
                      className="custom-table"
                    /> 
                    <div style={{ padding: '10px 5px', textAlign: 'center' }}>
                    <Pagination
                      className="custom-pagination"
                      align='end'
                      total={incomingMessages?.totalElements}
                      showTotal={(_, range) => `${range[0]}-${range[1]} of ${incomingMessages?.totalElements} items`}
                      current={pageNo}
                      pageSize={pageSize}
                      onChange={handlePaginationChange}
                      onShowSizeChange={(current, size) => {
                        setPageSize(size);
                        setPageNo(1);
                      }}
                      showSizeChanger
                    />
                    </div>
                  </Spin>   
                </div> 

                :
                <div className='data-table-section' style={{ height: '50vh' }}> 
                <Spin spinning={action.pending}>
                    <Table dataSource={outgoingMessages?.data} columns={columns}
                      pagination={false}
                      onChange={handleChange}
                      scroll={{ y: 'calc(65vh - 100px)',}}
                      sticky
                      className="custom-table"
                    /> 
                    <div style={{ padding: '10px 5px', textAlign: 'center' }}>
                    <Pagination
                      className="custom-pagination"
                      align='end'
                      total={outgoingMessages?.totalElements}
                      showTotal={(_, range) => `${range[0]}-${range[1]} of ${outgoingMessages?.totalElements} items`}
                      current={pageNo}
                      pageSize={pageSize}
                      onChange={handlePaginationChange}
                      onShowSizeChange={(current, size) => {
                        setPageSize(size);
                        setPageNo(1);
                      }}
                      showSizeChanger
                    />
                    </div>
                  </Spin>   
                </div> }
          
            </div>

            <Toaster position="top-right" expand={false} richColors/>

        </div>
    )
}

export default Messages