import React, { useState, useEffect } from 'react'
import "./webhooks.css"
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { getWebhooks } from '../../apis/Webhooks';
import {getRegisteredNumbers} from '../../apis/RegisterdNumbers'
import { useDispatch, useSelector } from 'react-redux';
import { Toaster, toast } from 'sonner'
import dateFormat from 'dateformat';

import { Table, Pagination, Spin }from "antd";

const Billing = () => {

    const dispatch = useDispatch()
    const action = useSelector((state) => state.action)

    const [webHooks, setWebHooks] = useState([])
  
    const [pageSize, setPageSize] = useState(25);
    const [pageNo, setPageNo] = useState(1);

    const [whatsappNumber, setWhatsappNumber] = useState('')
    const [registeredWabaNumbers, setRegisteredWabaNumbers] = useState([])
    const [sortedInfo, setSortedInfo] = useState({});

        
    useEffect(() =>{ 
        getWebhooks(dispatch, pageSize, pageNo, whatsappNumber).then(resp => {
            if(resp.status === 200){
                setWebHooks(resp.data?.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

           getRegisteredNumbers(dispatch).then(resp => {
            if(resp.status === 200){
              setRegisteredWabaNumbers(resp.data?.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

    }, [dispatch,pageNo, pageSize, whatsappNumber])

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
        //   backgroundColor: "#00B0E3",
          backgroundColor: theme.palette.common.black,
        //   boxShadow: theme.shadows[1],
          fontSize: 12,
        },
      }));


    const handleChange = (pagination, filters, sorter) => {
      console.log('Various parameters', pagination, filters, sorter);
      setSortedInfo(sorter);
    };
    const columns = [
      {
          title: 'Endpoint',
          dataIndex: 'endpoint',
          key: 'endpoint',
          width: 300,
          render: (_, record) => (
              <LightTooltip title={record.endpoint} placement="top-start" arrow>
                  <span>{record.endpoint}</span>
              </LightTooltip>
          ),
          sorter: (a, b) => a?.endpoint?.length - b?.endpoint?.length,
          sortOrder: sortedInfo.columnKey === 'endpoint' ? sortedInfo.order : null,
          ellipsis: true,
      },
      {
          title: 'Event Type',
          dataIndex: 'eventType',
          key: 'eventType',
          width: 180,
          sorter: (a, b) => a?.eventType?.length - b?.eventType?.length,
          sortOrder: sortedInfo.columnKey === 'eventType' ? sortedInfo.order : null,
          ellipsis: true,
      },
      {
          title: 'From',
          key: 'from',
          width: 180,
          render: (_, record) => <span>{JSON.parse(record.request).from?JSON.parse(record.request).from : '_ _' }</span>,
          sorter: (a, b) => JSON.parse(a?.request)?.from - JSON.parse(b?.request)?.from,
          sortOrder: sortedInfo.columnKey === 'from' ? sortedInfo.order : null,
          ellipsis: true,
      },
      {
          title: 'Message',
          dataIndex: 'text',
          key: 'text',
          width: 120,
          render: (_, record) => <span>{JSON.parse(record.request).text?JSON.parse(record.request).text: '_ _' }</span>,
          
      },
      {
          title: 'Profile Name',
          key: 'profileName',
          width: 150,
          render: (_, record) => <span>{JSON.parse(record.request)?.profileName?JSON.parse(record.request)?.profileName: "_ _"}</span>,
          sorter: (a, b) => JSON.parse(a?.request)?.profileName - JSON.parse(b?.request)?.profileName,
          sortOrder: sortedInfo.columnKey === 'profileName' ? sortedInfo.order : null,
          ellipsis: true,
      },
      {
          title: 'Retries',
          dataIndex: 'retries',
          key: 'retries',
          width: 70,
          sorter: (a, b) => a?.retries - b.retries,
          sortOrder: sortedInfo.columnKey === 'retries' ? sortedInfo.order : null,
          ellipsis: true,
      },
      {
          title: 'Created At',
          dataIndex: 'createdAt',
          key: 'createdAt',
          width: 150,
          render: (_, record) => <span>{dateFormat(record.createdAt, "dd/mm/yyyy HH:MM")}</span>,
          sorter: (a, b) => new Date(a?.createdAt) - new Date(b.createdAt),
          sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
          ellipsis: true,
      },
  ];

  const handlePaginationChange = (page, size) => {
    setPageNo(page);
    setPageSize(size);
  };

  

   

    return (
        <div class='table-page-api'>

              <p className='table-page-title-api'>Callbacks </p>
             
            <div>

            <div className='table-toolbar-api'>
                    <div className='filter-messages-section-api'>
                        <label className='filter-label'>Filter by business number: </label>
                        <select type='text' className='filter-select-field-api' value={whatsappNumber} onChange={(e) => { setWhatsappNumber(e.target.value) }}>
                        <option id='option' value="" selected>All</option>
                        <option id='option' value={`whatsappNumber=+254110090747`}>Sandbox Number</option>
                        {registeredWabaNumbers.map((item, id) =>
                            <option id='option' key={id} style={{ fontSize: '14px' }} value={`whatsappNumber=${item.number}`}>{item.number}</option>
                        )}
                        </select>
                    </div>
             </div>

            <div className='data-table-section' style={{ height: '74vh' }}> 
                <Spin spinning={action.pending}>
                    <Table dataSource={webHooks?.data} columns={columns}
                      pagination={false}
                      onChange={handleChange}
                      scroll={{ y: 'calc(87vh - 200px)',}}
                      sticky
                      className="custom-table"
                    /> 
                    <div style={{ padding: '10px 5px', textAlign: 'center' }}>
                    <Pagination
                      className="custom-pagination"
                      align='end'
                      total={webHooks?.totalElements}
                      showTotal={(_, range) => `${range[0]}-${range[1]} of ${webHooks?.totalElements} items`}
                      current={pageNo}
                      pageSize={pageSize}
                      onChange={handlePaginationChange}
                      onShowSizeChange={(current, size) => {
                        setPageSize(size);
                        setPageNo(1);
                      }}
                      showSizeChanger
                    />
                    </div>
                  </Spin>   
                </div> 
          
            </div>

            <Toaster position="top-right" expand={false} richColors/>

        </div>
    )
}

export default Billing