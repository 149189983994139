
import React from 'react'
import { NavLink } from 'react-router-dom';
import './sidebar.css'

const SideBar = ({ idName }) => {

  return (
    <div className='sidebar' id={idName}>
     
        <div className='logo-section'>
          <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="company-logo" className="sidebar-logo" />   
        </div>
          
        <div className='sidebar-menu-section'>
          <div className='menu-list'>
            <NavLink to='/app/home' className='heading-link'>
            <span class="material-symbols-outlined" id="h-icon">home</span>Home
            </NavLink>

            <NavLink to='/app/golive' className='heading-link'>
            <span class="material-symbols-outlined" id="h-icon">sensor_occupied</span>Go Live
            </NavLink>

            <NavLink to='/app/messages' className='heading-link'>
            <span class="material-symbols-outlined" id="h-icon">sms</span>Messages
            </NavLink>

            {/* <NavLink to='/app/chats' className='heading-link'>
            <span class="material-symbols-outlined" id="h-icon">forum</span>Chats
            </NavLink> */}

            <NavLink to='/app/web-hooks' className='heading-link'>
            <span class="material-symbols-outlined" id="h-icon">webhook</span>Callbacks
            </NavLink>

            <NavLink to='/app/settings' className='heading-link'>
            <span class="material-symbols-outlined" id="h-icon">settings</span>Settings
            </NavLink>

            {/* <NavLink to='/app/users' className='heading-link'>
            <span class="material-symbols-outlined" id="h-icon">group</span>Users
            </NavLink> */}

            <NavLink to='/app/billing' className='heading-link'>
            <span class="material-symbols-outlined" id="h-icon">credit_card</span>Billing
            </NavLink>

            <a  href="https://docs.lipachat.com/" target="_blank" rel="noreferrer" className='heading-link'>
            <span class="material-symbols-outlined" id="h-icon">article</span>Documentation
            </a>

          </div>


        </div>

  </div>

  )

}

export default SideBar