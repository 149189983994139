import React, { useState } from 'react'
import "./settings.css"
import { useDispatch, useSelector } from 'react-redux';

import Input from '../ReUsedComponents/inputField/Input'

import { Toaster, toast } from 'sonner'
import { generateApiKey, udapteCallbackUrl, statusCallbackUrl, 
  getPhoneNumbers, updatePhoneNumberCallback } from '../../apis/Settings' 
import { loginUser } from '../../apis/Authentication';
import { setUserInfo, setUserToken, setLoginState, setSandBoxNumber, 
  setApiKey, setCallbackUrl, setStatusCallbackUrl } from '../../redux-store/AuthSlice';

import { Table, Pagination, Spin }from "antd";

const Settings = () => {

    const dispatch = useDispatch()
    const action = useSelector((state) => state.action)

    const selector = useSelector((state) => state.auth)
    const userDetails = JSON.parse(selector?.userInfo)

    function isJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    }
    
    const userApiKey = JSON.parse(selector?.apiKey);
    const userCallbackUrl = isJSON(selector?.callbackUrl)? JSON.parse(selector?.callbackUrl):selector?.callbackUrl 
    const userStatusCallbackUrl = isJSON(selector?.statusCallbackUrl)? JSON.parse(selector?.statusCallbackUrl):selector?.statusCallbackUrl 

    const [isHidden, setIsHidden] = useState(true);
    const [password, setPassword] = useState('');
    const [formErrors, setFormErrors] = useState()

    const [passwordModal, setPasswordModal] = useState(false)
    const [callbackUrlModal, setCallbackUrlModal] = useState(false)
    const [statusCallbackUrlModal, setStatusCallbackUrlModal] = useState(false)
    const [phonenumberCallbackUrlModal, setPhonenumberCallbackUrlModal] = useState(false)

    const [webhookUrl, setWebhookUrl] = useState('');
    const [statusWebhookUrl, setStatusWebhookUrl] = useState('');

    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)
    const [step3, setStep3] = useState(false)
     
    const [phoneNumbers, setPhoneNumbers] = useState([])
    const [callbackDetails, setCallbackDetails] = useState({ phoneNumberId: '',callbackUrl: '', statusCallbackUrl: ''})

    useState(() =>{
       getPhoneNumbers(dispatch).then(resp => {
        setPhoneNumbers(resp.data)
       })
    }, [])


    const columns = [
      {
          title: 'Name',
          dataIndex: 'verifiedName',
          key: 'verifiedName',
          width: 200,
          sorter: (a, b) => a.verifiedName.length - b.verifiedName.length,
      },
      {
          title: 'Number',
          dataIndex: 'number',
          key: 'number',
          width: 150,
          sorter: (a, b) => a.number.length - b.number.length,
      },
      {
          title: 'Callback',
          dataIndex: 'callbackUrl',
          key: 'callbackUrl',
          width: 300,
          sorter: (a, b) => a.callbackUrl.length - b.callbackUrl.length,
      },
      {
          title: 'Status callback URL',
          key: 'statusCallbackUrl',
          width: 300,
          render: (_, record) => <span>{record.statusCallbackUrl ? record.statusCallbackUrl : '__'}</span>,
          sorter: (a, b) => (a.statusCallbackUrl?.length || 0) - (b.statusCallbackUrl?.length || 0),
      },
      {
          title: 'Actions',
          key: 'actions',
          width: 100,
          render: (_, record) => (
              <div className="contacts-table-actions">
                  <span
                      className="material-symbols-outlined"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                          setCallbackDetails({
                              phoneNumberId: record.id,
                              callbackUrl: record.callbackUrl,
                              statusCallbackUrl: record.statusCallbackUrl,
                          });
                          setPhonenumberCallbackUrlModal(true);
                      }}
                  >
                      edit
                  </span>
              </div>
          ),
      },
  ];
  

  const handleOnChange = (e) => {
    setCallbackDetails({ ...callbackDetails, [e.target.name]: e.target.value })
}

    const validate = () => {
        let errors = {}
        if (!password) {
          errors['password'] = 'Password is required!'
        }
        setFormErrors(errors)
        return errors
      };

      const validateUrl = () => {
        let errors = {}
        if (!webhookUrl) {
          errors['webhookUrl'] = 'Url is required!'
        }
    
        setFormErrors(errors)
        return errors
      };

      const validateStatusUrl = () => {
        let errors = {}
        if (!statusWebhookUrl) {
          errors['statusWebhookUrl'] = 'Url is required!'
        }
    
        setFormErrors(errors)
        return errors
      };

      const validateNumberCallbackUrl = () => {
        let errors = {}
        if (!callbackDetails.callbackUrl) {
          errors['callbackUrl'] = 'Field is required!'
        }
        if (!callbackDetails.statusCallbackUrl) {
          errors['statusCallbackUrl'] = 'Field is required!'
        }
    
        setFormErrors(errors)
        return errors
      };


    const copyKeyToClipboard = () => {
        navigator.clipboard.writeText(userDetails?.client?.apiKey)
          .then(() => {
            toast.success('API Key copied!')
          })
          .catch(err => {
            toast.error('Invalid username or password!')
          });
      };

    const copyCallbackToClipboard = () => {
      navigator.clipboard.writeText(userCallbackUrl)
        .then(() => {
          toast.success('Url copied!')
        })
        .catch(err => {
          toast.error('Invalid username or password!')
        });
    };

    const copyStatusCallbackToClipboard = () => {
      navigator.clipboard.writeText(userStatusCallbackUrl)
        .then(() => {
          toast.success('Url copied!')
        })
        .catch(err => {
          toast.error('Invalid username or password!')
        });
    };
 
    const loading = () =>{
        return <div class="lds-dual-ring"></div>
    }

    const handleGenerateKey = () => {
        if (Object.keys(validate()).length === 0) {

          loginUser(dispatch, {username: userDetails?.username, password: password}).then((resp) => {
            if (resp?.status === 200) {
                localStorage.setItem("token", resp?.data?.data?.token)
                localStorage.setItem("userinfo", JSON.stringify(resp?.data?.data?.user))
                localStorage.setItem("loginState", true)
                localStorage.setItem("sandBoxNumber", resp?.data?.data?.config?.sandboxNumber)
                dispatch(setUserToken(localStorage.getItem("token")))
                dispatch(setUserInfo(localStorage.getItem("userinfo")))
                dispatch(setLoginState(localStorage.getItem("loginState")))
                dispatch(setSandBoxNumber(localStorage.getItem("sandBoxNumber")))
               
                generateApiKey(dispatch).then(resp => {
                  console.log(resp)
                  if (resp?.status === 200) {
                    if (resp?.data.error === true) {
                      toast.error(resp?.data.message, { theme: "colored" })
                    } else {
                      localStorage.setItem("apikey", JSON.stringify(resp?.data?.data?.apiKey))
                      localStorage.setItem("callbackUrl", JSON.stringify(resp?.data?.data?.callbackUrl))
                      dispatch(setApiKey(localStorage.getItem("apikey")))
                      dispatch(setCallbackUrl(localStorage.getItem("callbackUrl")))
                      handleCloseModal(dispatch)
                      toast.success("Generated successful!", { theme: "colored" })
                      // window.location.reload()
                    }
                  }
                  else { toast.error("Error occurred!", { theme: "colored" }) }
                })
            }
            else if (resp?.response?.data?.message?.includes("User does not exists") || resp?.response?.status === 401) {
                toast.error('Invalid password!')
                console.log(resp)
            }

            else {
                toast.error('Something went wrong.Try again!')
                console.log(resp)
            }

        })
        
            
        }
    
      }

    const handleUpdateWebhook = () => {
        if (Object.keys(validateUrl()).length === 0) {
          udapteCallbackUrl(dispatch, {url: webhookUrl}).then(resp => {
                  console.log(resp)
                  if (resp?.status === 200) {
                    if (resp?.data.error === true) {
                      toast.error(resp?.data.message, { theme: "colored" })
                    } else {
                      localStorage.setItem("callbackUrl", webhookUrl)
                      dispatch(setCallbackUrl(localStorage.getItem("callbackUrl")))
                      handleCloseModal(dispatch)
                      toast.success("Generated successful!", { theme: "colored" })
                      // window.location.reload()
                    }
                  }
                  else { toast.error("Error occurred!", { theme: "colored" }) }
                })
            }
      }

    const configureStatusCallback = () => {
        if (Object.keys(validateStatusUrl()).length === 0) {
          statusCallbackUrl(dispatch, {url: statusWebhookUrl}).then(resp => {
                  if (resp?.status === 200) {
                      localStorage.setItem("statusCallbackUrl", statusWebhookUrl)
                      dispatch(setStatusCallbackUrl(localStorage.getItem("statusCallbackUrl")))
                      handleCloseModal(dispatch)
                      toast.success("Status Callback URL updated successfull!", { theme: "colored" })
                  }
                  else { toast.error("Error occurred!", { theme: "colored" }) }
                })
            }
      }

    const handleUpdatePhoneNumberCallback = () => {
        console.log(callbackDetails)
        if (Object.keys(validateNumberCallbackUrl()).length === 0) {
          updatePhoneNumberCallback(dispatch, callbackDetails).then(resp => {
                  console.log(resp)
                  if (resp?.status === 200) {
                    if (resp?.data.error === true) {
                      toast.error(resp?.data.message, { theme: "colored" })
                    } else {
                      handleCloseModal(dispatch)
                      toast.success("Updated successful!", { theme: "colored" })
                      // window.location.reload()
                    }
                  }
                  else { toast.error("Error occurred!", { theme: "colored" }) }
                })
            }
      }

    const handleCloseModal = () => {
        setPassword('')
        setPasswordModal(false)
        setCallbackUrlModal(false)
        setStatusCallbackUrlModal(false)
        setPhonenumberCallbackUrlModal(false)
        document.getElementById("form-modal").reset();
      }
    

    return (
        <div class='settings-page'>

            <p className='settings-page-title'>Account Settings</p>
              
            <div style={{display:"flex", marginBottom: '20px'}}>
              <p className={`settings-step-${step1}`} onClick={() => {setStep1(true); setStep2(false); setStep3(false)}}>Configurations</p>
              <p className={`settings-step-${step2}`} onClick={() => {setStep1(false); setStep2(true); setStep3(false)}}>Balance Alert</p>
              <p className={`settings-step-${step3}`} onClick={() => {setStep1(false); setStep2(false); setStep3(true)}}>Phone Numbers</p>
            </div>


            {step1? 
             <div className='apikey-callback-section'>

             <div className='apikey-credetial-sec'>
                <p className='apikey-credetial-title'>API Key</p>
                <div className='apikey-input-section'>
                  {isHidden? <p id='apikey-input-hidden-value'><span id='star-key-value'>{'****'.repeat(userDetails?.client?.apiKey.length / 4)}</span><span id='visibility-eye' onClick={()=>setIsHidden(false)} class="material-symbols-outlined">visibility</span></p>
                  :<p id='apikey-input-visible-value'>{userApiKey}<span id='visibility-eye' onClick={()=>setIsHidden(true)} class="material-symbols-outlined">visibility_off</span></p>
                  }
                  <span id='copy-btn' class="material-symbols-outlined" onClick={copyKeyToClipboard}>content_copy</span>
                </div>
                <button id='genearte-key-btn' onClick={()=> setPasswordModal(true)}>Generate New API Key</button>     
             </div>

              <div className='apikey-credetial-sec'>
                <p className='apikey-credetial-title'>Callback URL</p>
                <div className='apikey-input-section'>
                    <p id='apikey-input-visible-value'>{userCallbackUrl}</p>
                    <div id='credentials-btns'>
                        <span id='copy-btn' class="material-symbols-outlined" onClick={copyCallbackToClipboard}>content_copy</span>
                        <span id='copy-btn' class="material-symbols-outlined" onClick={()=> setCallbackUrlModal(true)}>Edit</span>
                    </div>
              </div>
            </div>  

         {/* /////////////////////configure status callback url */}
         <div className='apikey-credetial-sec'>
             <p className='apikey-credetial-title'>Status Callback URL</p>
             <div className='apikey-input-section'>
                 <p id='apikey-input-visible-value'>{userStatusCallbackUrl}</p>
                 <div id='credentials-btns'>
                     <span id='copy-btn' class="material-symbols-outlined" onClick={copyStatusCallbackToClipboard}>content_copy</span>
                     <span id='copy-btn' class="material-symbols-outlined" onClick={()=> setStatusCallbackUrlModal(true)}>Edit</span>
                 </div>
         </div>
         </div> 
     </div> :
            step2?
            <div className='apikey-credetial-sec'>
                  <p className='apikey-credetial-title'>Balance Alert</p>
                  <p style={{fontSize: '14px', fontWeight: '600'}}>We shall send you email notification whenever your account credits go lower than the level you specify below.</p>  
                  <input className='balance-alert-input' value='50' /> <br/>
                   <button id='genearte-key-btn'>Update Amount</button>
            </div> : 

          <div className='data-table-section' style={{ height: '74vh' }}> 
          <Spin spinning={action.pending}>
              <Table dataSource={phoneNumbers?.data} columns={columns}
                pagination={false}
                scroll={{ y: 'calc(87vh - 200px)',}}
                sticky
                className="custom-table"
              /> 
              <div style={{ padding: '10px 5px', textAlign: 'center' }}>
              <Pagination
                className="custom-pagination"
                align='end'
                total={phoneNumbers?.totalElements}
                showTotal={(_, range) => `${range[0]}-${range[1]} of ${phoneNumbers?.totalElements} items`}
                // current={pageNo}
                // pageSize={pageSize}
                // onChange={handlePaginationChange}
                // onShowSizeChange={(current, size) => {
                //   setPageSize(size);
                //   setPageNo(1);
                // }}
                showSizeChanger
              />
              </div>
            </Spin>   
          </div> 
            }
    

      {/* /////////////////////enter password modal  */}
      <div className={passwordModal ? 'create-modal-active' : 'create-modal-inactive'} >
        <form id="form-modal" className='view-modal-content'>
          <p className='modal-title'> Enter Password
          <span id='close-button' class="material-symbols-outlined" onClick={() => handleCloseModal(dispatch)}>close</span>
            </p>
            <p style={{fontSize: '13px', color: '#494F55'}}>NB: Generating a new API Key will result to old key not working</p>
          <div className="dialog-inputs">
          <div className="password-form-group">
                <input type='password' name='password' className="password-form-control" onChange={(e)=> setPassword(e.target.value)} />
                <span id="password-error">{formErrors?.password}</span>
            </div>
          </div>
          <div className='dialog-footer'>
            
            <div>
            {!action.pending?
                <button type='button' className="submit-btn" onClick={handleGenerateKey}>Generate</button>:
                <button className="submit-btn-disabled" disabled={true}>{loading()}</button>
            }
            </div>

          </div>
        </form>
      </div>

       {/* /////////////////////update callback url modal  */}
       <div className={callbackUrlModal ? 'create-modal-active' : 'create-modal-inactive'} >
        <form id="form-modal" className='view-modal-content'>
          <p className='modal-title'> Enter Url
          <span id='close-button' class="material-symbols-outlined" onClick={() => handleCloseModal()}>close</span>
            </p>
          <div className="dialog-inputs">
          <div className="password-form-group">
                <input type='text' name='webhookUrl' className="password-form-control" onChange={(e)=> setWebhookUrl(e.target.value)} />
                <span id="password-error">{formErrors?.webhookUrl}</span>
            </div>
          </div>
          <div className='dialog-footer'>
            
            <div>
            {!action.pending?
                <button type='button' className="submit-btn" onClick={handleUpdateWebhook}>Update</button>:
                <button className="submit-btn-disabled" disabled={true}>{loading()}</button>
            }
            </div>

          </div>
        </form>
      </div>

       {/* /////////////////////configure status callback url modal  */}
       <div className={statusCallbackUrlModal ? 'create-modal-active' : 'create-modal-inactive'} >
        <form id="form-modal" className='view-modal-content'>
          <p className='modal-title'> Enter Url
          <span id='close-button' class="material-symbols-outlined" onClick={() => handleCloseModal()}>close</span>
            </p>
          <div className="dialog-inputs">
          <div className="password-form-group">
                <input type='text' name='statusWebhookUrl' className="password-form-control" onChange={(e)=> setStatusWebhookUrl(e.target.value)} />
                <span id="password-error">{formErrors?.statusWebhookUrl}</span>
            </div>
          </div>
          <div className='dialog-footer'>
            
            <div>
            {!action.pending?
                <button type='button' className="submit-btn" onClick={configureStatusCallback}>Update</button>:
                <button className="submit-btn-disabled" disabled={true}>{loading()}</button>
            }
            </div>

          </div>
        </form>
      </div>



         {/* /////////////////////update phonenumber callback url modal  */}
         <div className={phonenumberCallbackUrlModal ? 'create-modal-active' : 'create-modal-inactive'} >
          <form id="form-modal" className='view-modal-content'>
              <p className='modal-title'> Update Callback URL
                 <span id='close-button' class="material-symbols-outlined" onClick={() => handleCloseModal()}>close</span>
              </p>

              <div className="dialog-inputs">
                <Input label="Callback URL *" name='callbackUrl' type='text' value={callbackDetails?.callbackUrl} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.callbackUrl}/>
                <Input label="Status Callback Url *" name='statusCallbackUrl' type='text' value={callbackDetails?.statusCallbackUrl} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.statusCallbackUrl}/>
              </div>
            
              <div className='dialog-footer'>
                {!action.pending?
                    <button type='button' className="submit-btn" onClick={handleUpdatePhoneNumberCallback}>Update</button>:
                    <button className="submit-btn-disabled" disabled={true}>{loading()}</button>
                }
              </div>
            </form>
        </div>


        <Toaster position="top-right" expand={false} richColors/>
        </div>
    )
}

export default Settings